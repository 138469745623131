var scrollTopBtn = document.getElementById('top-scroll');
var scrollTopViewToggle = function () {
	if (document.scrollingElement.scrollTop > 400) {
		if (!scrollTopBtn.classList.contains('is-active')) {
			scrollTopBtn.classList.add('is-active');
		}
	} else {
		if (scrollTopBtn.classList.contains('is-active')) {
			scrollTopBtn.classList.remove('is-active');
		}
	}
}
window.addEventListener('scroll', scrollTopViewToggle, true);